import axios from 'axios';

import { getClient } from '../lib/apiClient';
import analytics from '../lib/analytics';
import {
  FETCHING_FAVORITES,
  RECEIVE_FAVORITES,
  ADD_FAVORITES,
  REMOVE_FAVORITES,
  FETCHING_PROGRAMS,
  RECEIVE_PROGRAMS,
} from '../reducers/user';

export function getUserFavorites() {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCHING_FAVORITES,
    });
    const profile = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).get('/api/v1/user_list_items/current');
    dispatch({
      type: RECEIVE_FAVORITES,
      favorites: profile.data,
    });
  };
}

export function getUserPrograms(applicantUserId) {
  return async (dispatch, getState) => {
    if (applicantUserId) {
      dispatch({
        type: FETCHING_PROGRAMS,
      });
      const programLeads = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post(
        '/api/v1/leads/status/programsByApplicantId',
        { applicantUserId },
      );
      dispatch({
        type: RECEIVE_PROGRAMS,
        programs: programLeads.data,
      });
    }
  };
}

export function addUserFavorite(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_FAVORITES,
      favorites: [id],
    });
    let newKey;
    try {
      newKey = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/user_list_items/upsert', {
        itemKey: id,
        status: 'active',
      });
      dispatch({
        type: RECEIVE_FAVORITES,
        favorites: [newKey.data],
      });
    } catch (e) {
      dispatch({
        type: REMOVE_FAVORITES,
        favorites: [id],
      });
    }
    return newKey?.data;
  };
}

export function removeUserFavorite(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: REMOVE_FAVORITES,
      favorites: [id],
    });
    try {
      const newKey = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/user_list_items/upsert', {
        itemKey: id,
        status: 'removed',
      });
      dispatch({
        type: RECEIVE_FAVORITES,
        favorites: [newKey.data],
      });
    } catch (e) {
      dispatch({
        type: ADD_FAVORITES,
        favorites: [id],
      });
    }
  };
}

export function updateUserFavorite(updatedItem) {
  return async (dispatch, getState) => {
    const updatedItemResponse = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post(
      '/api/v1/user_list_items/upsert',
      updatedItem,
      { params: { deepMerge: true } },
    );
    dispatch({
      type: RECEIVE_FAVORITES,
      favorites: [updatedItemResponse.data],
    });
  };
}

export async function touchEmail(email) {
  const result = axios.post(`${process.env.NEXT_PUBLIC_API_DOMAIN}/api/v1/users/touch`, { email });
  if (result) {
    analytics.track('emailSignup', {
      category: 'user',
    });
  }
}

export function sendThankYou(id) {
  return async (dispatch, getState) => {
    await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post(
      '/api/v1/user_list_items/sendThankYou',
      { id },
    );
  };
}
